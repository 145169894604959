/* eslint-disable */
import './App.css';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';

function App() {
  const [starterProject, setStarterProject] = useState('');
  const [description, setDescription] = useState('');
  const [molecularProfile, setMolecularProfile] = useState('');
  const [flavor, setFlavor] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [ingredientFile, setIngredientFile] = useState(null);
  const [equipmentFile, setEquipmentFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [status, setStatus] = useState('');

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setResult(''); // Clear previous results

    const queryParams = new URLSearchParams({
      gene_name: starterProject,
      disease_input: description,
      output_variable_input: molecularProfile,
      hypothesis_type: flavor,
      known_hypotheses_input: additionalInfo,
    }).toString();

    const endpoint = `https://algenius-api.northeurope.cloudapp.azure.com/api/v1/generate-hypotheses?${queryParams}`;

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
      });

      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let resultData = ''; // eslint-disable-next-line

        // Stream the response in chunks
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setStatus("Complete")
            break
          };
          if (value) {
            setShowResult(true)
          };
          const chunk = decoder.decode(value, { stream: true });
          // resultData += decoder.decode(value, { stream: true });
          const messages = chunk.trim().split("\n");
          messages.forEach(message=>{ // eslint-disable-next-line
            const parsedMessage = JSON.parse(message);
            if(parsedMessage.type === "AIMessage") {
              resultData = '';
              setLoading(false)
              resultData += parsedMessage.data;
              // setResult(prevResult => prevResult + resultData)
              setResult(resultData)
              setStatus("Generating")
            }
            if(parsedMessage.type === "ToolMessage") {
              setStatus("Excecuting tools")
              setLoading(true)
              // console.log(parsedMessage)
            }
            if(parsedMessage.type === "HumanMessage") {
              setStatus("Revising")
              setLoading(true)
              // console.log(parsedMessage)
            }
          })
          // setResult(prevResult => prevResult + resultData); // Update progressively
        }

         // Show result and hide input fields
      } else {
        setResult('Error: Failed to generate protocols.');
      }
    } catch (error) {
      setResult('Error: Failed to generate protocols.');
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setShowResult(false);
    setStarterProject('');
    setDescription('');
    setMolecularProfile('');
    setFlavor('');
    setAdditionalInfo('');
    setIngredientFile(null);
    setEquipmentFile(null);
    setResult('');
  };

  return (
    <div className="App">
      <nav className="navbar">
        <div className="navbar-logo">
          <h1>Algenius Foods</h1>
        </div>
      </nav>
      <header className="App-header">
        <div className={`overlay ${loading ? 'active' : ''}`}>
          <div className="loading-spinner"></div>
        </div>
        {!showResult ? (
          <div className="container">
            <div className="left-section">
              <div className="upload-section">
                <label className="upload-label">
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, setIngredientFile)}
                    className="upload-input"
                  />
                  <span className="upload-icon">+</span>
                  <span className="upload-text">Upload Ingredient List</span>
                </label>
              </div>
              <div className="upload-section">
                <label className="upload-label">
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, setEquipmentFile)}
                    className="upload-input"
                  />
                  <span className="upload-icon">+</span>
                  <span className="upload-text">Upload Equipment List</span>
                </label>
              </div>
            </div>
            <div className="right-section">
              <div className="input-field">
                <input
                  type="text"
                  value={starterProject}
                  onChange={(e) => setStarterProject(e.target.value)}
                  placeholder="Enter starter project"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description of the product"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  value={molecularProfile}
                  onChange={(e) => setMolecularProfile(e.target.value)}
                  placeholder="Enter molecular profile"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  value={flavor}
                  onChange={(e) => setFlavor(e.target.value)}
                  placeholder="Enter the flavor to achieve"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                  placeholder="Additional Information"
                />
              </div>
              <button onClick={handleSubmit} className="generate-button" disabled={loading}>
                {loading ? 'Generating...' : 'Generate Protocols'}
              </button>
            </div>
          </div>
        ) : (
          <div className="result-container">
            <button onClick={handleReset} className="reset-button">Generate New Protocol</button>
            <div>Status: {status}</div>
            <div className="result">
              <ReactMarkdown>{result}</ReactMarkdown>
            </div>
          </div>
        )}
      </header>
      <footer className="App-footer">
        <p>&copy; 2024 Algenius Foods. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
